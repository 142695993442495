<template>
  <p
    v-if="props.withHeading && props.heading"
    :class="[props.headingClass ? props.headingClass : 'inline-block text-sm font-bold text-primary']"
  >
    {{ props.heading }}
  </p>

  <div
    class="flex items-center justify-center gap-2  text-xs  text-primary"
    :class="[props.withHeading ? 'flex-row text-right' : 'flex-col text-center']"
    :style="{ minWidth: '8ch', maxWidth: '16ch' }"
    v-bind="$attrs"
  >
    <UiImage
      v-if="props.image"
      :src="props.image"
      :width="IMAGE_DATA.dimensions.width"
      :height="IMAGE_DATA.dimensions.height"
      :class="IMAGE_DATA.styles"
    />

    <p
      v-if="props.description && formatPackageLabel"
      class="w-full whitespace-pre-wrap break-words"
      :style="{
        fontSize: 'inherit',
        lineHeight: 'inherit',
      }"
    >
      {{ formatPackageLabel(props.description) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import UiImage from '../../UiImage/UiImage.vue'
import formatPackageLabel from '../../../utils/formatPackageLabel'
import type { UiProductItemPackagingProps } from './UiProductItemPackaging.types'

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<UiProductItemPackagingProps>(), {
  image: '',
  description: '',
  heading: '',
  withHeading: false,
  headingClass: '',
})

const IMAGE_DATA = {
  dimensions: {
    width: 25,
    height: 25,
  },
  styles: 'mb-1',
}
</script>
